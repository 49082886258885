import { Foundation } from 'foundation-sites';
import * as CoreUtils from 'foundation-sites';
import { onImagesLoaded } from 'foundation-sites';
import { Box } from "foundation-sites";
import { Keyboard } from 'foundation-sites';
import { MediaQuery } from 'foundation-sites';
import { Nest } from 'foundation-sites';
import { Timer } from 'foundation-sites';
import { Touch } from 'foundation-sites';
import { Triggers } from 'foundation-sites';
import { Accordion } from "foundation-sites";
import { AccordionMenu } from 'foundation-sites';
import { Drilldown } from 'foundation-sites';
import { Dropdown } from "foundation-sites";
import { DropdownMenu } from 'foundation-sites';
import { Reveal } from 'foundation-sites';
import { Sticky } from 'foundation-sites';
import { OffCanvas } from 'foundation-sites';
import { Toggler } from "foundation-sites";
import { Tabs } from "foundation-sites";
import { SmoothScroll } from 'foundation-sites';
import { Abide } from 'foundation-sites';

import $ from "jquery";

Foundation.addToJquery($);
Foundation.onImagesLoaded = onImagesLoaded;
jQuery(document).foundation();
