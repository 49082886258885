import $ from 'jquery';

global.$ = $;
global.jQuery = $;

import './foundation';
import './app';
import './site';
import './leaflet';
import './accordeon';

